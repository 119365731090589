export const Person = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 7.063C16.5 10.258 14.57 13 12 13c-2.572 0-4.5-2.742-4.5-5.938C7.5 3.868 9.16 2 12 2s4.5 1.867 4.5 5.063zM4.102 20.142C4.487 20.6 6.145 22 12 22c5.855 0 7.512-1.4 7.898-1.857a.416.416 0 0 0 .09-.317C19.9 18.944 19.106 15 12 15s-7.9 3.944-7.989 4.826a.416.416 0 0 0 .091.317z"
                fill="#000000"
            />
        </svg>

    )
}
export const Key = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="#000000"
  width="20px"
  height="20px"
  viewBox="0 0 32 32"
  version="1.1"
>
  <title>key</title>
  <path d="M9.938 20.25l-1.219-1.25s-3.469 1.344-6.969-2.125c-2.969-2.969-1.656-7.625 0.281-9.531 2.063-2.063 6.469-3.313 9.688-0.094s1.844 7.031 1.844 7.031l8.844 8.813-0.25 4.594-4.5 0.281-0.25-2.25-2.344-0.281-0.219-2.344-2.313-0.313-0.344-2.25zM3.25 8.594c-1.375 1.375-1.75 3.219-0.844 4.094 0.906 0.906 2.719 0.531 4.094-0.844s1.781-3.219 0.875-4.094c-0.875-0.906-2.75-0.531-4.125 0.844zM21.344 24.844l0.063-1.313-8.406-8.406-0.688 0.688z" />
</svg>

  )
}
export const Arrow = () => {
    return (
        <svg
            fill="#000000"
            height="20px"
            width="20px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 330 330"
            xmlSpace="preserve"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                    id="XMLID_225_"
                    d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                />{" "}
            </g>
        </svg>

    )
}